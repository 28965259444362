@import '../partials';

.teaser-grid {

  &__boundary {

  }

  .section-header {

  }

  .post-teasers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .teaser {
      flex-basis: 100%;

      @include bp(md) {
        flex-basis: 50%;
      }

      &__boundary {
        position: relative;
        display: flex;
        width: 100%;
        max-height: 425px;
        overflow: hidden;
      }

      &__image {
        display: block;
        width: 100%;
        max-height: 100%;

        img {
          display: block;
          width: 100%;
        }
      }

      &__label {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        color: $white;
        padding: rem(16) rem(18);
        text-shadow: 0 -2px 3px rgba(0, 0, 0, 0.5),
                     0 2px 3px rgba(0, 0, 0, 0.5);

        &::before,
        &::after {
          content: " ";
          width: inherit;
          height: inherit;
          position: absolute;
          left: 0;
          top: 0;
          transition: opacity 0.25s;
          transition-delay: 0s;
        }

        &::before {
          @include overlay-gradient-slight;
          opacity: 1;
        }

        &::after {
          @include overlay-gradient-strong;
          opacity: 0;
        }

        @include bp(md) {
          &:hover {
            &::before {
              opacity: 0;
            }

            &::after {
              opacity: 1;
            }
          }
        }
      }

      &__label-innertube {
        flex-basis: 68%;
        padding: rem(20);
        z-index: 1;
        max-width: rem(400);
      }

      &__description-wrapper {
        display: none;
        overflow-y: hidden;
        max-height: 0.01px;
        opacity: 0;
        transition-property: max-height, opacity;
        transition-duration: 0.75s, 0.3s;

        @include bp(md) {
          display: block;
        }
      }

      &:hover {
        .teaser__description-wrapper {
          @include bp(md) {
            opacity: 1;
            max-height: rem(400);
          }
        }
      }

      &__title {
        font-size: rem(40);
        font-weight: $font-weight-heavy;
        text-transform: uppercase;

        @include bp(xs2) {
          font-size: rem(54);
        }
      }

      &__description {
        margin-bottom: rem(18);
        line-height: rem(29);
      }

      &__link {
        @include box-button($white);
        display: inline-block;
      }

      &__image-credit {
        flex-basis: 32%;
        font-size: rem(9);
        text-align: right;
        font-family: $sans-serif-body-font;
        font-weight: $font-weight-normal;
        z-index: 1;
      }
    }
  }
}
